[data-color-mode="dark"][data-dark-theme="dark_dimmed"],
[data-color-mode="dark"][data-dark-theme="dark_dimmed"] ::backdrop,
[data-color-mode="auto"][data-light-theme="dark_dimmed"],
[data-color-mode="auto"][data-light-theme="dark_dimmed"] ::backdrop {
  --button-danger-bgColor-active: #c33d38;
  --button-danger-fgColor-rest: #ea5c53;
  --button-danger-iconColor-rest: #ea5c53;
  --button-outline-fgColor-disabled: #478be680;
  --button-primary-bgColor-active: #428f46;
  --button-primary-bgColor-disabled: #50a254;
  --button-primary-bgColor-hover: #3b8640;
  --button-primary-borderColor-disabled: #50a254;
  --button-primary-fgColor-rest: #ffffff;
  --button-primary-iconColor-rest: #ffffff;
  --color-ansi-cyan: #39c5cf;
  --color-ansi-cyan-bright: #56d4dd;
  --control-borderColor-rest: #3d444d;
  --control-checked-bgColor-active: #3f7fdb;
  --control-checked-bgColor-hover: #3876d3;
  --control-checked-borderColor-active: #3f7fdb;
  --control-checked-borderColor-hover: #3876d3;
  --fgColor-accent: #478be6;
  --reactionButton-selected-bgColor-hover: #4285e55c;
  --avatar-shadow: 0px 0px 0px 2px #0d1117;
  --avatarStack-fade-bgColor-default: #3d444d;
  --avatarStack-fade-bgColor-muted: #2a313c;
  --bgColor-accent-emphasis: #316dca;
  --bgColor-accent-muted: #4184e41a;
  --bgColor-attention-emphasis: #966600;
  --bgColor-attention-muted: #ae7c1426;
  --bgColor-danger-emphasis: #c93c37;
  --bgColor-danger-muted: #e5534b1a;
  --bgColor-default: #212830;
  --bgColor-disabled: #2a313c;
  --bgColor-done-emphasis: #8256d0;
  --bgColor-done-muted: #986ee226;
  --bgColor-emphasis: #3d444d;
  --bgColor-inset: #151b23;
  --bgColor-muted: #262c36;
  --bgColor-neutral-emphasis: #656c76;
  --bgColor-neutral-muted: #656c7633;
  --bgColor-severe-emphasis: #ae5622;
  --bgColor-severe-muted: #cc6b2c1a;
  --bgColor-sponsors-emphasis: #ae4c82;
  --bgColor-sponsors-muted: #c961981a;
  --bgColor-success-emphasis: #347d39;
  --bgColor-success-muted: #46954a26;
  --bgColor-transparent: #00000000;
  --borderColor-accent-emphasis: #316dca;
  --borderColor-accent-muted: #4184e466;
  --borderColor-attention-emphasis: #966600;
  --borderColor-attention-muted: #ae7c1466;
  --borderColor-danger-emphasis: #c93c37;
  --borderColor-danger-muted: #e5534b66;
  --borderColor-default: #3d444d;
  --borderColor-disabled: #656c761a;
  --borderColor-done-emphasis: #8256d0;
  --borderColor-done-muted: #986ee266;
  --borderColor-emphasis: #656c76;
  --borderColor-muted: #3d444db3;
  --borderColor-neutral-muted: #3d444db3;
  --borderColor-severe-emphasis: #ae5622;
  --borderColor-severe-muted: #cc6b2c66;
  --borderColor-sponsors-emphasis: #ae4c82;
  --borderColor-sponsors-muted: #c9619866;
  --borderColor-success-emphasis: #347d39;
  --borderColor-success-muted: #46954a66;
  --borderColor-transparent: #00000000;
  --button-danger-bgColor-hover: #ad2e2c;
  --button-danger-borderColor-rest: var(--control-borderColor-rest);
  --button-danger-shadow-selected: 0px 0px 0px 0px #000000;
  --button-default-borderColor-rest: var(--control-borderColor-rest);
  --button-default-shadow-resting: 0px 0px 0px 0px #000000;
  --button-inactive-bgColor: #2a313c;
  --button-inactive-fgColor: #656c76;
  --button-invisible-bgColor-disabled: #00000000;
  --button-invisible-borderColor-disabled: #00000000;
  --button-invisible-iconColor-hover: #b7bdc8;
  --button-invisible-iconColor-rest: #b7bdc8;
  --button-outline-bgColor-active: #1b4b91;
  --button-outline-bgColor-rest: #f0f6fc;
  --button-outline-fgColor-hover: #539bf5;
  --button-outline-fgColor-rest: #4184e4;
  --button-outline-shadow-selected: 0px 0px 0px 0px #000000;
  --button-primary-shadow-selected: 0px 0px 0px 0px #000000;
  --button-star-iconColor: #daaa3f;
  --buttonCounter-danger-bgColor-rest: #5d0f1233;
  --buttonCounter-default-bgColor-rest: #2f3742;
  --buttonCounter-outline-bgColor-hover: #0f2d5c33;
  --buttonCounter-outline-bgColor-rest: #0f2d5c33;
  --buttonCounter-outline-fgColor-disabled: #478be680;
  --buttonCounter-outline-fgColor-hover: #539bf5;
  --buttonCounter-outline-fgColor-rest: #4184e4;
  --buttonCounter-primary-bgColor-rest: #11341733;
  --codeMirror-syntax-fgColor-comment: #656c76;
  --codeMirror-syntax-fgColor-constant: #6cb6ff;
  --codeMirror-syntax-fgColor-entity: #dcbdfb;
  --codeMirror-syntax-fgColor-keyword: #f47067;
  --codeMirror-syntax-fgColor-storage: #f47067;
  --codeMirror-syntax-fgColor-string: #96d0ff;
  --codeMirror-syntax-fgColor-support: #6cb6ff;
  --codeMirror-syntax-fgColor-variable: #f69d50;
  --color-ansi-black: #2f3742;
  --color-ansi-black-bright: #656c76;
  --color-ansi-blue: #539bf5;
  --color-ansi-blue-bright: #6cb6ff;
  --color-ansi-gray: #656c76;
  --color-ansi-green: #57ab5a;
  --color-ansi-green-bright: #6bc46d;
  --color-ansi-magenta: #b083f0;
  --color-ansi-magenta-bright: #dcbdfb;
  --color-ansi-red: #f47067;
  --color-ansi-red-bright: #ff938a;
  --color-ansi-white: #f0f6fc;
  --color-ansi-yellow: #c69026;
  --color-ansi-yellow-bright: #daaa3f;
  --color-prettylights-syntax-brackethighlighter-angle: #9198a1;
  --color-prettylights-syntax-brackethighlighter-unmatched: #e5534b;
  --color-prettylights-syntax-carriage-return-bg: #ad2e2c;
  --color-prettylights-syntax-carriage-return-text: #f0f6fc;
  --color-prettylights-syntax-comment: #9198a1;
  --color-prettylights-syntax-constant: #6cb6ff;
  --color-prettylights-syntax-constant-other-reference-link: #96d0ff;
  --color-prettylights-syntax-entity: #dcbdfb;
  --color-prettylights-syntax-entity-tag: #8ddb8c;
  --color-prettylights-syntax-invalid-illegal-bg: #922323;
  --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
  --color-prettylights-syntax-keyword: #f47067;
  --color-prettylights-syntax-markup-bold: #f0f6fc;
  --color-prettylights-syntax-markup-changed-bg: #682d0f;
  --color-prettylights-syntax-markup-changed-text: #ffddb0;
  --color-prettylights-syntax-markup-deleted-bg: #78191b;
  --color-prettylights-syntax-markup-deleted-text: #ffd8d3;
  --color-prettylights-syntax-markup-heading: #316dca;
  --color-prettylights-syntax-markup-ignored-bg: #255ab2;
  --color-prettylights-syntax-markup-ignored-text: #f0f6fc;
  --color-prettylights-syntax-markup-inserted-bg: #1b4721;
  --color-prettylights-syntax-markup-inserted-text: #b4f1b4;
  --color-prettylights-syntax-markup-italic: #f0f6fc;
  --color-prettylights-syntax-markup-list: #eac55f;
  --color-prettylights-syntax-meta-diff-range: #dcbdfb;
  --color-prettylights-syntax-storage-modifier-import: #f0f6fc;
  --color-prettylights-syntax-string: #96d0ff;
  --color-prettylights-syntax-string-regexp: #8ddb8c;
  --color-prettylights-syntax-sublimelinter-gutter-mark: #3d444d;
  --color-prettylights-syntax-variable: #f69d50;
  --contribution-default-bgColor-0: #2a313c;
  --contribution-default-bgColor-1: #1b4721;
  --contribution-default-bgColor-2: #2b6a30;
  --contribution-default-bgColor-3: #46954a;
  --contribution-default-bgColor-4: #6bc46d;
  --contribution-default-borderColor-0: #cdd9e50d;
  --contribution-default-borderColor-1: #cdd9e50d;
  --contribution-default-borderColor-2: #cdd9e50d;
  --contribution-default-borderColor-3: #cdd9e50d;
  --contribution-default-borderColor-4: #cdd9e50d;
  --contribution-halloween-bgColor-1: #fac68f;
  --contribution-halloween-bgColor-2: #c46212;
  --contribution-halloween-bgColor-3: #984b10;
  --contribution-halloween-bgColor-4: #e3d04f;
  --contribution-winter-bgColor-1: #143d79;
  --contribution-winter-bgColor-2: #255ab2;
  --contribution-winter-bgColor-3: #539bf5;
  --contribution-winter-bgColor-4: #c6e6ff;
  --control-bgColor-active: #3d444d;
  --control-bgColor-hover: #2f3742;
  --control-bgColor-rest: #2a313c;
  --control-borderColor-disabled: #656c761a;
  --control-borderColor-selected: #f0f6fc;
  --control-danger-fgColor-hover: #f47067;
  --control-transparent-bgColor-active: #656c7633;
  --control-transparent-bgColor-hover: #656c7626;
  --control-transparent-bgColor-rest: #00000000;
  --control-transparent-bgColor-selected: #656c761a;
  --control-transparent-borderColor-active: #00000000;
  --control-transparent-borderColor-hover: #00000000;
  --control-transparent-borderColor-rest: #00000000;
  --controlTrack-bgColor-active: #3d444d;
  --controlTrack-bgColor-hover: #2f3742;
  --controlTrack-bgColor-rest: #2a313c;
  --controlTrack-borderColor-rest: #3d444d;
  --counter-borderColor: #00000000;
  --data-auburn-color-emphasis: #a86f6b;
  --data-auburn-color-muted: #271817;
  --data-blue-color-emphasis: #0576ff;
  --data-blue-color-muted: #001a47;
  --data-brown-color-emphasis: #94774c;
  --data-brown-color-muted: #241c14;
  --data-coral-color-emphasis: #e1430e;
  --data-coral-color-muted: #351008;
  --data-gray-color-emphasis: #576270;
  --data-gray-color-muted: #1c1c1c;
  --data-green-color-emphasis: #2f6f37;
  --data-green-color-muted: #122117;
  --data-lemon-color-emphasis: #977b0c;
  --data-lemon-color-muted: #291d00;
  --data-lime-color-emphasis: #5f892f;
  --data-lime-color-muted: #141f0f;
  --data-olive-color-emphasis: #7a8321;
  --data-olive-color-muted: #171e0b;
  --data-orange-color-emphasis: #984b10;
  --data-orange-color-muted: #311708;
  --data-pine-color-emphasis: #18915e;
  --data-pine-color-muted: #082119;
  --data-pink-color-emphasis: #d34591;
  --data-pink-color-muted: #2d1524;
  --data-plum-color-emphasis: #b643ef;
  --data-plum-color-muted: #2a0e3f;
  --data-purple-color-emphasis: #975bf1;
  --data-purple-color-muted: #211047;
  --data-red-color-emphasis: #eb3342;
  --data-red-color-muted: #3c0614;
  --data-teal-color-emphasis: #106c70;
  --data-teal-color-muted: #041f25;
  --data-yellow-color-emphasis: #895906;
  --data-yellow-color-muted: #2e1a00;
  --diffBlob-additionNum-bgColor: #57ab5a4d;
  --diffBlob-additionWord-bgColor: #46954a66;
  --diffBlob-deletionNum-bgColor: #e5534b4d;
  --diffBlob-deletionWord-bgColor: #e5534b66;
  --diffBlob-hunkNum-bgColor-rest: #143d79;
  --display-auburn-bgColor-emphasis: #87534f;
  --display-auburn-bgColor-muted: #271817;
  --display-auburn-borderColor-emphasis: #a86f6b;
  --display-auburn-borderColor-muted: #3a2422;
  --display-auburn-fgColor: #bf9592;
  --display-blue-bgColor-emphasis: #005bd1;
  --display-blue-bgColor-muted: #001a47;
  --display-blue-borderColor-emphasis: #0576ff;
  --display-blue-borderColor-muted: #002766;
  --display-blue-fgColor: #4da0ff;
  --display-brown-bgColor-emphasis: #755e3e;
  --display-brown-bgColor-muted: #241c14;
  --display-brown-borderColor-emphasis: #94774c;
  --display-brown-borderColor-muted: #342a1d;
  --display-brown-fgColor: #b69a6d;
  --display-coral-bgColor-emphasis: #c31328;
  --display-coral-bgColor-muted: #3c0614;
  --display-coral-borderColor-emphasis: #eb3342;
  --display-coral-borderColor-muted: #58091a;
  --display-coral-fgColor: #f27d83;
  --display-cyan-bgColor-emphasis: #036a8c;
  --display-cyan-bgColor-muted: #001f29;
  --display-cyan-borderColor-emphasis: #0587b3;
  --display-cyan-borderColor-muted: #002e3d;
  --display-cyan-fgColor: #07ace4;
  --display-gray-bgColor-emphasis: #576270;
  --display-gray-bgColor-muted: #1c1c1c;
  --display-gray-borderColor-emphasis: #6e7f96;
  --display-gray-borderColor-muted: #2a2b2d;
  --display-gray-fgColor: #92a1b5;
  --display-green-bgColor-emphasis: #2f6f37;
  --display-green-bgColor-muted: #122117;
  --display-green-borderColor-emphasis: #388f3f;
  --display-green-borderColor-muted: #182f1f;
  --display-green-fgColor: #41b445;
  --display-indigo-bgColor-emphasis: #514ed4;
  --display-indigo-bgColor-muted: #1b183f;
  --display-indigo-borderColor-emphasis: #7070e1;
  --display-indigo-borderColor-muted: #25215f;
  --display-indigo-fgColor: #9899ec;
  --display-lemon-bgColor-emphasis: #786008;
  --display-lemon-bgColor-muted: #291d00;
  --display-lemon-borderColor-emphasis: #977b0c;
  --display-lemon-borderColor-muted: #372901;
  --display-lemon-fgColor: #ba9b12;
  --display-lime-bgColor-emphasis: #496c28;
  --display-lime-bgColor-muted: #141f0f;
  --display-lime-borderColor-emphasis: #5f892f;
  --display-lime-borderColor-muted: #1f3116;
  --display-lime-fgColor: #7dae37;
  --display-olive-bgColor-emphasis: #5e681d;
  --display-olive-bgColor-muted: #171e0b;
  --display-olive-borderColor-emphasis: #7a8321;
  --display-olive-borderColor-muted: #252d10;
  --display-olive-fgColor: #a2a626;
  --display-orange-bgColor-emphasis: #984b10;
  --display-orange-bgColor-muted: #311708;
  --display-orange-borderColor-emphasis: #c46212;
  --display-orange-borderColor-muted: #43200a;
  --display-orange-fgColor: #ed8326;
  --display-pine-bgColor-emphasis: #14714c;
  --display-pine-bgColor-muted: #082119;
  --display-pine-borderColor-emphasis: #18915e;
  --display-pine-borderColor-muted: #0b3224;
  --display-pine-fgColor: #1bb673;
  --display-pink-bgColor-emphasis: #ac2f74;
  --display-pink-bgColor-muted: #2d1524;
  --display-pink-borderColor-emphasis: #d34591;
  --display-pink-borderColor-muted: #451c35;
  --display-pink-fgColor: #e57bb2;
  --display-plum-bgColor-emphasis: #9518d8;
  --display-plum-bgColor-muted: #2a0e3f;
  --display-plum-borderColor-emphasis: #b643ef;
  --display-plum-borderColor-muted: #40125e;
  --display-plum-fgColor: #d07ef7;
  --display-purple-bgColor-emphasis: #7730e8;
  --display-purple-bgColor-muted: #211047;
  --display-purple-borderColor-emphasis: #975bf1;
  --display-purple-borderColor-muted: #31146b;
  --display-purple-fgColor: #b687f7;
  --display-red-bgColor-emphasis: #c31328;
  --display-red-bgColor-muted: #3c0614;
  --display-red-borderColor-emphasis: #eb3342;
  --display-red-borderColor-muted: #58091a;
  --display-red-fgColor: #f27d83;
  --display-teal-bgColor-emphasis: #106c70;
  --display-teal-bgColor-muted: #041f25;
  --display-teal-borderColor-emphasis: #158a8a;
  --display-teal-borderColor-muted: #073036;
  --display-teal-fgColor: #1cb0ab;
  --display-yellow-bgColor-emphasis: #895906;
  --display-yellow-bgColor-muted: #2e1a00;
  --display-yellow-borderColor-emphasis: #aa7109;
  --display-yellow-borderColor-muted: #3d2401;
  --display-yellow-fgColor: #d3910d;
  --fgColor-attention: #c69026;
  --fgColor-danger: #e5534b;
  --fgColor-default: #d1d7e0;
  --fgColor-disabled: #656c76;
  --fgColor-done: #986ee2;
  --fgColor-link: var(--fgColor-accent);
  --fgColor-muted: #9198a1;
  --fgColor-neutral: #9198a1;
  --fgColor-onEmphasis: #f0f6fc;
  --fgColor-severe: #cc6b2c;
  --fgColor-sponsors: #c96198;
  --fgColor-success: #57ab5a;
  --header-bgColor: #151b23f2;
  --header-borderColor-divider: #656c76;
  --header-fgColor-logo: #f0f6fc;
  --headerSearch-bgColor: #0d1117;
  --headerSearch-borderColor: #2a313c;
  --highlight-neutral-bgColor: #c6902666;
  --label-auburn-bgColor-active: #543331;
  --label-auburn-bgColor-hover: #3a2422;
  --label-auburn-bgColor-rest: #271817;
  --label-auburn-fgColor-active: #d4b7b5;
  --label-auburn-fgColor-hover: #c6a19f;
  --label-auburn-fgColor-rest: #bf9592;
  --label-blue-bgColor-active: #00378a;
  --label-blue-bgColor-hover: #002766;
  --label-blue-bgColor-rest: #001a47;
  --label-blue-fgColor-active: #85c2ff;
  --label-blue-fgColor-hover: #61adff;
  --label-blue-fgColor-rest: #4da0ff;
  --label-brown-bgColor-active: #483a28;
  --label-brown-bgColor-hover: #342a1d;
  --label-brown-bgColor-rest: #241c14;
  --label-brown-fgColor-active: #cdbb98;
  --label-brown-fgColor-hover: #bfa77d;
  --label-brown-fgColor-rest: #b69a6d;
  --label-coral-bgColor-active: #72220d;
  --label-coral-bgColor-hover: #51180b;
  --label-coral-bgColor-rest: #351008;
  --label-coral-fgColor-active: #fdaa86;
  --label-coral-fgColor-hover: #fa8c61;
  --label-coral-fgColor-rest: #f7794b;
  --label-cyan-bgColor-active: #014156;
  --label-cyan-bgColor-hover: #002e3d;
  --label-cyan-bgColor-rest: #001f29;
  --label-cyan-fgColor-active: #45cbf7;
  --label-cyan-fgColor-hover: #09b7f1;
  --label-cyan-fgColor-rest: #07ace4;
  --label-gray-bgColor-active: #393d41;
  --label-gray-bgColor-hover: #2a2b2d;
  --label-gray-bgColor-rest: #1c1c1c;
  --label-gray-fgColor-active: #b3c0d1;
  --label-gray-fgColor-hover: #9babbf;
  --label-gray-fgColor-rest: #92a1b5;
  --label-green-bgColor-active: #214529;
  --label-green-bgColor-hover: #182f1f;
  --label-green-bgColor-rest: #122117;
  --label-green-fgColor-active: #75d36f;
  --label-green-fgColor-hover: #46c144;
  --label-green-fgColor-rest: #41b445;
  --label-indigo-bgColor-active: #312c90;
  --label-indigo-bgColor-hover: #25215f;
  --label-indigo-bgColor-rest: #1b183f;
  --label-indigo-fgColor-active: #b7baf6;
  --label-indigo-fgColor-hover: #a2a5f1;
  --label-indigo-fgColor-rest: #9899ec;
  --label-lemon-bgColor-active: #4f3c02;
  --label-lemon-bgColor-hover: #372901;
  --label-lemon-bgColor-rest: #291d00;
  --label-lemon-fgColor-active: #d7bc1d;
  --label-lemon-fgColor-hover: #c4a717;
  --label-lemon-fgColor-rest: #ba9b12;
  --label-lime-bgColor-active: #2c441d;
  --label-lime-bgColor-hover: #1f3116;
  --label-lime-bgColor-rest: #141f0f;
  --label-lime-fgColor-active: #9fcc3e;
  --label-lime-fgColor-hover: #89ba36;
  --label-lime-fgColor-rest: #7dae37;
  --label-olive-bgColor-active: #374115;
  --label-olive-bgColor-hover: #252d10;
  --label-olive-bgColor-rest: #171e0b;
  --label-olive-fgColor-active: #cbc025;
  --label-olive-fgColor-hover: #b2af24;
  --label-olive-fgColor-rest: #a2a626;
  --label-orange-bgColor-active: #632f0d;
  --label-orange-bgColor-hover: #43200a;
  --label-orange-bgColor-rest: #311708;
  --label-orange-fgColor-active: #f6b06a;
  --label-orange-fgColor-hover: #f1933b;
  --label-orange-fgColor-rest: #ed8326;
  --label-pine-bgColor-active: #0e4430;
  --label-pine-bgColor-hover: #0b3224;
  --label-pine-bgColor-rest: #082119;
  --label-pine-fgColor-active: #1bda81;
  --label-pine-fgColor-hover: #1ac176;
  --label-pine-fgColor-rest: #1bb673;
  --label-pink-bgColor-active: #65244a;
  --label-pink-bgColor-hover: #451c35;
  --label-pink-bgColor-rest: #2d1524;
  --label-pink-fgColor-active: #f4a9cd;
  --label-pink-fgColor-hover: #ec8dbd;
  --label-pink-fgColor-rest: #e57bb2;
  --label-plum-bgColor-active: #5c1688;
  --label-plum-bgColor-hover: #40125e;
  --label-plum-bgColor-rest: #2a0e3f;
  --label-plum-fgColor-active: #e4a5fd;
  --label-plum-fgColor-hover: #d889fa;
  --label-plum-fgColor-rest: #d07ef7;
  --label-purple-bgColor-active: #481a9e;
  --label-purple-bgColor-hover: #31146b;
  --label-purple-bgColor-rest: #211047;
  --label-purple-fgColor-active: #d2affd;
  --label-purple-fgColor-hover: #c398fb;
  --label-purple-fgColor-rest: #b687f7;
  --label-red-bgColor-active: #790c20;
  --label-red-bgColor-hover: #58091a;
  --label-red-bgColor-rest: #3c0614;
  --label-red-fgColor-active: #f7adab;
  --label-red-fgColor-hover: #f48b8d;
  --label-red-fgColor-rest: #f27d83;
  --label-teal-bgColor-active: #0a464d;
  --label-teal-bgColor-hover: #073036;
  --label-teal-bgColor-rest: #041f25;
  --label-teal-fgColor-active: #24d6c4;
  --label-teal-fgColor-hover: #1fbdb2;
  --label-teal-fgColor-rest: #1cb0ab;
  --label-yellow-bgColor-active: #5a3702;
  --label-yellow-bgColor-hover: #3d2401;
  --label-yellow-bgColor-rest: #2e1a00;
  --label-yellow-fgColor-active: #edb431;
  --label-yellow-fgColor-hover: #df9e11;
  --label-yellow-fgColor-rest: #d3910d;
  --menu-bgColor-active: #151b23;
  --overlay-backdrop-bgColor: #262c3666;
  --overlay-bgColor: #2a313c;
  --overlay-borderColor: #3d444db3;
  --reactionButton-selected-bgColor-rest: #4184e433;
  --reactionButton-selected-fgColor-hover: #6cb6ff;
  --selectMenu-bgColor-active: #143d79;
  --sideNav-bgColor-selected: #212830;
  --skeletonLoader-bgColor: #656c7633;
  --timelineBadge-bgColor: #262c36;
  --topicTag-borderColor: #00000000;
  --underlineNav-borderColor-active: #ec775c;
  --avatar-bgColor: #cdd9e51a;
  --bgColor-black: #1c2128;
  --bgColor-closed-emphasis: var(--bgColor-danger-emphasis);
  --bgColor-closed-muted: var(--bgColor-danger-muted);
  --bgColor-inverse: #cdd9e5;
  --bgColor-open-emphasis: var(--bgColor-success-emphasis);
  --bgColor-open-muted: var(--bgColor-success-muted);
  --bgColor-upsell-emphasis: var(--bgColor-done-emphasis);
  --bgColor-upsell-muted: var(--bgColor-done-muted);
  --bgColor-white: #cdd9e5;
  --border-accent-emphasis: 0.0625rem solid #316dca;
  --border-accent-muted: 0.0625rem solid #4184e466;
  --border-attention-emphasis: 0.0625rem solid #966600;
  --border-attention-muted: 0.0625rem solid #ae7c1466;
  --border-danger-emphasis: 0.0625rem solid #c93c37;
  --border-danger-muted: 0.0625rem solid #e5534b66;
  --border-default: 0.0625rem solid #3d444d;
  --border-disabled: 0.0625rem solid #656c761a;
  --border-done-emphasis: 0.0625rem solid #8256d0;
  --border-done-muted: 0.0625rem solid #986ee266;
  --border-emphasis: 0.0625rem solid #656c76;
  --border-muted: 0.0625rem solid #3d444db3;
  --border-neutral-muted: 0.0625rem solid #3d444db3;
  --border-severe-emphasis: 0.0625rem solid #ae5622;
  --border-severe-muted: 0.0625rem solid #cc6b2c66;
  --border-sponsors-emphasis: 0.0625rem solid #ae4c82;
  --border-sponsors-muted: 0.0625rem solid #c9619866;
  --border-success-emphasis: 0.0625rem solid #347d39;
  --border-success-muted: 0.0625rem solid #46954a66;
  --border-transparent: 0.0625rem solid #00000000;
  --borderColor-closed-emphasis: var(--borderColor-danger-emphasis);
  --borderColor-closed-muted: var(--borderColor-danger-muted);
  --borderColor-neutral-emphasis: var(--borderColor-emphasis);
  --borderColor-open-emphasis: var(--borderColor-success-emphasis);
  --borderColor-open-muted: var(--borderColor-success-muted);
  --borderColor-translucent: #cdd9e526;
  --borderColor-upsell-emphasis: var(--borderColor-done-emphasis);
  --borderColor-upsell-muted: var(--borderColor-done-muted);
  --button-danger-bgColor-rest: var(--control-bgColor-rest);
  --button-danger-fgColor-active: #cdd9e5;
  --button-danger-fgColor-disabled: #e5534b80;
  --button-danger-fgColor-hover: #cdd9e5;
  --button-danger-iconColor-hover: #cdd9e5;
  --button-default-bgColor-active: var(--control-bgColor-active);
  --button-default-bgColor-hover: var(--control-bgColor-hover);
  --button-default-bgColor-rest: var(--control-bgColor-rest);
  --button-default-bgColor-selected: var(--control-bgColor-active);
  --button-default-borderColor-active: var(--button-default-borderColor-rest);
  --button-default-borderColor-disabled: var(--control-borderColor-disabled);
  --button-default-borderColor-hover: var(--button-default-borderColor-rest);
  --button-invisible-bgColor-active: var(--control-transparent-bgColor-active);
  --button-invisible-bgColor-hover: var(--control-transparent-bgColor-hover);
  --button-invisible-bgColor-rest: var(--control-transparent-bgColor-rest);
  --button-invisible-borderColor-hover: var(--control-transparent-borderColor-hover);
  --button-invisible-borderColor-rest: var(--control-transparent-borderColor-rest);
  --button-outline-bgColor-hover: var(--control-bgColor-hover);
  --button-outline-fgColor-active: #cdd9e5;
  --button-primary-bgColor-rest: var(--bgColor-success-emphasis);
  --button-primary-borderColor-rest: #cdd9e51a;
  --button-primary-fgColor-disabled: #cdd9e566;
  --buttonCounter-danger-bgColor-disabled: #c93c370d;
  --buttonCounter-danger-bgColor-hover: #cdd9e533;
  --buttonCounter-danger-fgColor-disabled: #e5534b80;
  --buttonCounter-danger-fgColor-hover: #cdd9e5;
  --buttonCounter-danger-fgColor-rest: var(--fgColor-danger);
  --buttonCounter-invisible-bgColor-rest: var(--bgColor-neutral-muted);
  --buttonCounter-outline-bgColor-disabled: #316dca0d;
  --card-bgColor: var(--bgColor-muted);
  --codeMirror-activeline-bgColor: var(--bgColor-neutral-muted);
  --codeMirror-bgColor: var(--bgColor-default);
  --codeMirror-cursor-fgColor: var(--fgColor-default);
  --codeMirror-fgColor: var(--fgColor-default);
  --codeMirror-gutterMarker-fgColor-default: var(--bgColor-default);
  --codeMirror-gutterMarker-fgColor-muted: var(--fgColor-muted);
  --codeMirror-gutters-bgColor: var(--bgColor-default);
  --codeMirror-lineNumber-fgColor: var(--fgColor-muted);
  --codeMirror-lines-bgColor: var(--bgColor-default);
  --codeMirror-matchingBracket-fgColor: var(--fgColor-default);
  --codeMirror-selection-bgColor: var(--borderColor-accent-muted);
  --color-ansi-white-bright: #cdd9e5;
  --control-bgColor-disabled: var(--bgColor-disabled);
  --control-bgColor-selected: var(--control-bgColor-rest);
  --control-borderColor-danger: var(--borderColor-danger-emphasis);
  --control-borderColor-emphasis: var(--borderColor-emphasis);
  --control-borderColor-success: var(--borderColor-success-emphasis);
  --control-borderColor-warning: var(--borderColor-attention-emphasis);
  --control-checked-bgColor-disabled: var(--fgColor-disabled);
  --control-checked-bgColor-rest: var(--bgColor-accent-emphasis);
  --control-checked-fgColor-disabled: #1c2128;
  --control-checked-fgColor-rest: var(--fgColor-onEmphasis);
  --control-danger-bgColor-active: #e5534b66;
  --control-danger-bgColor-hover: var(--bgColor-danger-muted);
  --control-danger-fgColor-rest: var(--fgColor-danger);
  --control-fgColor-disabled: var(--fgColor-disabled);
  --control-fgColor-placeholder: var(--fgColor-muted);
  --control-fgColor-rest: var(--fgColor-default);
  --control-iconColor-rest: var(--fgColor-muted);
  --control-transparent-bgColor-disabled: var(--bgColor-disabled);
  --controlKnob-bgColor-checked: #cdd9e5;
  --controlKnob-bgColor-rest: var(--bgColor-inset);
  --controlTrack-bgColor-disabled: var(--fgColor-disabled);
  --controlTrack-borderColor-disabled: var(--fgColor-disabled);
  --controlTrack-fgColor-disabled: var(--fgColor-onEmphasis);
  --controlTrack-fgColor-rest: var(--fgColor-muted);
  --counter-bgColor-emphasis: var(--bgColor-neutral-emphasis);
  --counter-bgColor-muted: var(--bgColor-neutral-muted);
  --data-auburn-color-gradientStop-1: var(--data-auburn-color-muted);
  --data-auburn-color-gradientStop-2: #27181700;
  --data-blue-color-gradientStop-1: var(--data-blue-color-muted);
  --data-blue-color-gradientStop-2: #001a4700;
  --data-brown-color-gradientStop-1: var(--data-brown-color-muted);
  --data-brown-color-gradientStop-2: #241c1400;
  --data-coral-color-gradientStop-1: var(--data-coral-color-muted);
  --data-coral-color-gradientStop-2: #35100800;
  --data-gray-color-gradientStop-1: var(--data-gray-color-muted);
  --data-gray-color-gradientStop-2: #1c1c1c00;
  --data-green-color-gradientStop-1: var(--data-green-color-muted);
  --data-green-color-gradientStop-2: #12211700;
  --data-lemon-color-gradientStop-1: var(--data-lemon-color-muted);
  --data-lemon-color-gradientStop-2: #291d0000;
  --data-lime-color-gradientStop-1: var(--data-lime-color-muted);
  --data-lime-color-gradientStop-2: #141f0f00;
  --data-olive-color-gradientStop-1: var(--data-olive-color-muted);
  --data-olive-color-gradientStop-2: #171e0b00;
  --data-orange-color-gradientStop-1: var(--data-orange-color-muted);
  --data-orange-color-gradientStop-2: #31170800;
  --data-pine-color-gradientStop-1: var(--data-pine-color-muted);
  --data-pine-color-gradientStop-2: #08211900;
  --data-pink-color-gradientStop-1: var(--data-pink-color-muted);
  --data-pink-color-gradientStop-2: #2d152400;
  --data-plum-color-gradientStop-1: var(--data-plum-color-muted);
  --data-plum-color-gradientStop-2: #2a0e3f00;
  --data-purple-color-gradientStop-1: var(--data-purple-color-muted);
  --data-purple-color-gradientStop-2: #21104700;
  --data-red-color-gradientStop-1: var(--data-red-color-muted);
  --data-red-color-gradientStop-2: #3c061400;
  --data-teal-color-gradientStop-1: var(--data-teal-color-muted);
  --data-teal-color-gradientStop-2: #041f2500;
  --data-yellow-color-gradientStop-1: var(--data-yellow-color-muted);
  --data-yellow-color-gradientStop-2: #2e1a0000;
  --diffBlob-additionLine-bgColor: var(--bgColor-success-muted);
  --diffBlob-additionLine-fgColor: var(--fgColor-default);
  --diffBlob-additionNum-fgColor: var(--fgColor-default);
  --diffBlob-additionWord-fgColor: var(--fgColor-default);
  --diffBlob-deletionLine-bgColor: var(--bgColor-danger-muted);
  --diffBlob-deletionLine-fgColor: var(--fgColor-default);
  --diffBlob-deletionNum-fgColor: var(--fgColor-default);
  --diffBlob-deletionWord-fgColor: var(--fgColor-default);
  --diffBlob-emptyLine-bgColor: var(--bgColor-muted);
  --diffBlob-emptyNum-bgColor: var(--bgColor-muted);
  --diffBlob-expander-iconColor: var(--fgColor-muted);
  --diffBlob-hunkLine-bgColor: var(--bgColor-accent-muted);
  --diffBlob-hunkLine-fgColor: var(--fgColor-muted);
  --diffBlob-hunkNum-bgColor-hover: var(--bgColor-accent-emphasis);
  --diffBlob-hunkNum-fgColor-hover: var(--fgColor-onEmphasis);
  --diffBlob-hunkNum-fgColor-rest: var(--fgColor-default);
  --fgColor-black: #1c2128;
  --fgColor-closed: var(--fgColor-danger);
  --fgColor-onInverse: #1c2128;
  --fgColor-open: var(--fgColor-success);
  --fgColor-upsell: var(--fgColor-done);
  --fgColor-white: #cdd9e5;
  --focus-outlineColor: var(--borderColor-accent-emphasis);
  --header-fgColor-default: #cdd9e5b3;
  --page-header-bgColor: var(--bgColor-default);
  --reactionButton-selected-fgColor-rest: var(--fgColor-link);
  --selectMenu-borderColor: var(--borderColor-default);
  --selection-bgColor: #316dcab3;
  --shadow-floating-large: 0px 0px 0px 1px #3d444d, 0px 24px 48px 0px #1c2128;
  --shadow-floating-legacy: 0px 6px 12px -3px #1c212866, 0px 6px 18px 0px #1c212866;
  --shadow-floating-medium: 0px 0px 0px 1px #3d444d, 0px 8px 16px -4px #1c212866, 0px 4px 32px -4px #1c212866, 0px 24px 48px -12px #1c212866, 0px 48px 96px -24px #1c212866;
  --shadow-floating-small: 0px 0px 0px 1px #3d444d, 0px 6px 12px -3px #1c212866, 0px 6px 18px 0px #1c212866;
  --shadow-floating-xlarge: 0px 0px 0px 1px #3d444d, 0px 32px 64px 0px #1c2128;
  --shadow-inset: inset 0px 1px 0px 0px #1c21283d;
  --shadow-resting-medium: 0px 1px 1px 0px #1c212866, 0px 3px 6px 0px #1c2128cc;
  --shadow-resting-small: 0px 1px 1px 0px #1c212899, 0px 1px 3px 0px #1c212899;
  --shadow-resting-xsmall: 0px 1px 1px 0px #1c2128cc;
  --tooltip-bgColor: var(--bgColor-emphasis);
  --tooltip-fgColor: var(--fgColor-onEmphasis);
  --treeViewItem-leadingVisual-iconColor-rest: var(--fgColor-muted);
  --underlineNav-borderColor-hover: var(--borderColor-neutral-muted);
  --underlineNav-iconColor-rest: var(--fgColor-muted);
  --avatar-borderColor: var(--borderColor-translucent);
  --border-closed-emphasis: var(--border-danger-emphasis);
  --border-closed-muted: var(--border-danger-muted);
  --border-neutral-emphasis: 0.0625rem solid #656c76;
  --border-open-emphasis: var(--border-success-emphasis);
  --border-open-muted: var(--border-success-muted);
  --border-upsell-emphasis: 0.0625rem solid #8256d0;
  --border-upsell-muted: 0.0625rem solid #986ee266;
  --button-danger-bgColor-disabled: var(--control-bgColor-disabled);
  --button-danger-borderColor-hover: var(--button-primary-borderColor-rest);
  --button-default-bgColor-disabled: var(--control-bgColor-disabled);
  --button-default-fgColor-rest: var(--control-fgColor-rest);
  --button-invisible-fgColor-active: var(--control-fgColor-rest);
  --button-invisible-fgColor-disabled: var(--control-fgColor-disabled);
  --button-invisible-fgColor-hover: var(--control-fgColor-rest);
  --button-invisible-fgColor-rest: var(--control-fgColor-rest);
  --button-invisible-iconColor-disabled: var(--control-fgColor-disabled);
  --button-outline-bgColor-disabled: var(--control-bgColor-disabled);
  --button-outline-borderColor-hover: var(--button-default-borderColor-hover);
  --button-primary-borderColor-active: var(--button-primary-borderColor-rest);
  --button-primary-borderColor-hover: var(--button-primary-borderColor-rest);
  --control-checked-borderColor-disabled: var(--control-checked-bgColor-disabled);
  --control-checked-borderColor-rest: var(--control-checked-bgColor-rest);
  --controlKnob-bgColor-disabled: var(--control-bgColor-disabled);
  --controlKnob-borderColor-checked: var(--control-checked-bgColor-rest);
  --controlKnob-borderColor-disabled: var(--control-bgColor-disabled);
  --controlKnob-borderColor-rest: var(--control-borderColor-emphasis);
  --data-auburn-gradient: linear-gradient(180deg, #271817 0%, #27181700 100%);
  --data-blue-gradient: linear-gradient(180deg, #001a47 0%, #001a4700 100%);
  --data-brown-gradient: linear-gradient(180deg, #241c14 0%, #241c1400 100%);
  --data-coral-gradient: linear-gradient(180deg, #351008 0%, #35100800 100%);
  --data-gray-gradient: linear-gradient(180deg, #1c1c1c 0%, #1c1c1c00 100%);
  --data-green-gradient: linear-gradient(180deg, #122117 0%, #12211700 100%);
  --data-lemon-gradient: linear-gradient(180deg, #291d00 0%, #291d0000 100%);
  --data-lime-gradient: linear-gradient(180deg, #141f0f 0%, #141f0f00 100%);
  --data-olive-gradient: linear-gradient(180deg, #171e0b 0%, #171e0b00 100%);
  --data-orange-gradient: linear-gradient(180deg, #311708 0%, #31170800 100%);
  --data-pine-gradient: linear-gradient(180deg, #082119 0%, #08211900 100%);
  --data-pink-gradient: linear-gradient(180deg, #2d1524 0%, #2d152400 100%);
  --data-plum-gradient: linear-gradient(180deg, #2a0e3f 0%, #2a0e3f00 100%);
  --data-purple-gradient: linear-gradient(180deg, #211047 0%, #21104700 100%);
  --data-red-gradient: linear-gradient(180deg, #3c0614 0%, #3c061400 100%);
  --data-teal-gradient: linear-gradient(180deg, #041f25 0%, #041f2500 100%);
  --data-yellow-gradient: linear-gradient(180deg, #2e1a00 0%, #2e1a0000 100%);
  --focus-outline: 2px solid #316dca;
  --button-danger-borderColor-active: var(--button-danger-borderColor-hover);
  --button-outline-borderColor-active: var(--button-outline-borderColor-hover);
}
@media (prefers-color-scheme: dark) {
  [data-color-mode="auto"][data-dark-theme="dark_dimmed"],
  [data-color-mode="auto"][data-dark-theme="dark_dimmed"] ::backdrop {
    --button-danger-bgColor-active: #c33d38;
    --button-danger-fgColor-rest: #ea5c53;
    --button-danger-iconColor-rest: #ea5c53;
    --button-outline-fgColor-disabled: #478be680;
    --button-primary-bgColor-active: #428f46;
    --button-primary-bgColor-disabled: #50a254;
    --button-primary-bgColor-hover: #3b8640;
    --button-primary-borderColor-disabled: #50a254;
    --button-primary-fgColor-rest: #ffffff;
    --button-primary-iconColor-rest: #ffffff;
    --color-ansi-cyan: #39c5cf;
    --color-ansi-cyan-bright: #56d4dd;
    --control-borderColor-rest: #3d444d;
    --control-checked-bgColor-active: #3f7fdb;
    --control-checked-bgColor-hover: #3876d3;
    --control-checked-borderColor-active: #3f7fdb;
    --control-checked-borderColor-hover: #3876d3;
    --fgColor-accent: #478be6;
    --reactionButton-selected-bgColor-hover: #4285e55c;
    --avatar-shadow: 0px 0px 0px 2px #0d1117;
    --avatarStack-fade-bgColor-default: #3d444d;
    --avatarStack-fade-bgColor-muted: #2a313c;
    --bgColor-accent-emphasis: #316dca;
    --bgColor-accent-muted: #4184e41a;
    --bgColor-attention-emphasis: #966600;
    --bgColor-attention-muted: #ae7c1426;
    --bgColor-danger-emphasis: #c93c37;
    --bgColor-danger-muted: #e5534b1a;
    --bgColor-default: #212830;
    --bgColor-disabled: #2a313c;
    --bgColor-done-emphasis: #8256d0;
    --bgColor-done-muted: #986ee226;
    --bgColor-emphasis: #3d444d;
    --bgColor-inset: #151b23;
    --bgColor-muted: #262c36;
    --bgColor-neutral-emphasis: #656c76;
    --bgColor-neutral-muted: #656c7633;
    --bgColor-severe-emphasis: #ae5622;
    --bgColor-severe-muted: #cc6b2c1a;
    --bgColor-sponsors-emphasis: #ae4c82;
    --bgColor-sponsors-muted: #c961981a;
    --bgColor-success-emphasis: #347d39;
    --bgColor-success-muted: #46954a26;
    --bgColor-transparent: #00000000;
    --borderColor-accent-emphasis: #316dca;
    --borderColor-accent-muted: #4184e466;
    --borderColor-attention-emphasis: #966600;
    --borderColor-attention-muted: #ae7c1466;
    --borderColor-danger-emphasis: #c93c37;
    --borderColor-danger-muted: #e5534b66;
    --borderColor-default: #3d444d;
    --borderColor-disabled: #656c761a;
    --borderColor-done-emphasis: #8256d0;
    --borderColor-done-muted: #986ee266;
    --borderColor-emphasis: #656c76;
    --borderColor-muted: #3d444db3;
    --borderColor-neutral-muted: #3d444db3;
    --borderColor-severe-emphasis: #ae5622;
    --borderColor-severe-muted: #cc6b2c66;
    --borderColor-sponsors-emphasis: #ae4c82;
    --borderColor-sponsors-muted: #c9619866;
    --borderColor-success-emphasis: #347d39;
    --borderColor-success-muted: #46954a66;
    --borderColor-transparent: #00000000;
    --button-danger-bgColor-hover: #ad2e2c;
    --button-danger-borderColor-rest: var(--control-borderColor-rest);
    --button-danger-shadow-selected: 0px 0px 0px 0px #000000;
    --button-default-borderColor-rest: var(--control-borderColor-rest);
    --button-default-shadow-resting: 0px 0px 0px 0px #000000;
    --button-inactive-bgColor: #2a313c;
    --button-inactive-fgColor: #656c76;
    --button-invisible-bgColor-disabled: #00000000;
    --button-invisible-borderColor-disabled: #00000000;
    --button-invisible-iconColor-hover: #b7bdc8;
    --button-invisible-iconColor-rest: #b7bdc8;
    --button-outline-bgColor-active: #1b4b91;
    --button-outline-bgColor-rest: #f0f6fc;
    --button-outline-fgColor-hover: #539bf5;
    --button-outline-fgColor-rest: #4184e4;
    --button-outline-shadow-selected: 0px 0px 0px 0px #000000;
    --button-primary-shadow-selected: 0px 0px 0px 0px #000000;
    --button-star-iconColor: #daaa3f;
    --buttonCounter-danger-bgColor-rest: #5d0f1233;
    --buttonCounter-default-bgColor-rest: #2f3742;
    --buttonCounter-outline-bgColor-hover: #0f2d5c33;
    --buttonCounter-outline-bgColor-rest: #0f2d5c33;
    --buttonCounter-outline-fgColor-disabled: #478be680;
    --buttonCounter-outline-fgColor-hover: #539bf5;
    --buttonCounter-outline-fgColor-rest: #4184e4;
    --buttonCounter-primary-bgColor-rest: #11341733;
    --codeMirror-syntax-fgColor-comment: #656c76;
    --codeMirror-syntax-fgColor-constant: #6cb6ff;
    --codeMirror-syntax-fgColor-entity: #dcbdfb;
    --codeMirror-syntax-fgColor-keyword: #f47067;
    --codeMirror-syntax-fgColor-storage: #f47067;
    --codeMirror-syntax-fgColor-string: #96d0ff;
    --codeMirror-syntax-fgColor-support: #6cb6ff;
    --codeMirror-syntax-fgColor-variable: #f69d50;
    --color-ansi-black: #2f3742;
    --color-ansi-black-bright: #656c76;
    --color-ansi-blue: #539bf5;
    --color-ansi-blue-bright: #6cb6ff;
    --color-ansi-gray: #656c76;
    --color-ansi-green: #57ab5a;
    --color-ansi-green-bright: #6bc46d;
    --color-ansi-magenta: #b083f0;
    --color-ansi-magenta-bright: #dcbdfb;
    --color-ansi-red: #f47067;
    --color-ansi-red-bright: #ff938a;
    --color-ansi-white: #f0f6fc;
    --color-ansi-yellow: #c69026;
    --color-ansi-yellow-bright: #daaa3f;
    --color-prettylights-syntax-brackethighlighter-angle: #9198a1;
    --color-prettylights-syntax-brackethighlighter-unmatched: #e5534b;
    --color-prettylights-syntax-carriage-return-bg: #ad2e2c;
    --color-prettylights-syntax-carriage-return-text: #f0f6fc;
    --color-prettylights-syntax-comment: #9198a1;
    --color-prettylights-syntax-constant: #6cb6ff;
    --color-prettylights-syntax-constant-other-reference-link: #96d0ff;
    --color-prettylights-syntax-entity: #dcbdfb;
    --color-prettylights-syntax-entity-tag: #8ddb8c;
    --color-prettylights-syntax-invalid-illegal-bg: #922323;
    --color-prettylights-syntax-invalid-illegal-text: #f0f6fc;
    --color-prettylights-syntax-keyword: #f47067;
    --color-prettylights-syntax-markup-bold: #f0f6fc;
    --color-prettylights-syntax-markup-changed-bg: #682d0f;
    --color-prettylights-syntax-markup-changed-text: #ffddb0;
    --color-prettylights-syntax-markup-deleted-bg: #78191b;
    --color-prettylights-syntax-markup-deleted-text: #ffd8d3;
    --color-prettylights-syntax-markup-heading: #316dca;
    --color-prettylights-syntax-markup-ignored-bg: #255ab2;
    --color-prettylights-syntax-markup-ignored-text: #f0f6fc;
    --color-prettylights-syntax-markup-inserted-bg: #1b4721;
    --color-prettylights-syntax-markup-inserted-text: #b4f1b4;
    --color-prettylights-syntax-markup-italic: #f0f6fc;
    --color-prettylights-syntax-markup-list: #eac55f;
    --color-prettylights-syntax-meta-diff-range: #dcbdfb;
    --color-prettylights-syntax-storage-modifier-import: #f0f6fc;
    --color-prettylights-syntax-string: #96d0ff;
    --color-prettylights-syntax-string-regexp: #8ddb8c;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #3d444d;
    --color-prettylights-syntax-variable: #f69d50;
    --contribution-default-bgColor-0: #2a313c;
    --contribution-default-bgColor-1: #1b4721;
    --contribution-default-bgColor-2: #2b6a30;
    --contribution-default-bgColor-3: #46954a;
    --contribution-default-bgColor-4: #6bc46d;
    --contribution-default-borderColor-0: #cdd9e50d;
    --contribution-default-borderColor-1: #cdd9e50d;
    --contribution-default-borderColor-2: #cdd9e50d;
    --contribution-default-borderColor-3: #cdd9e50d;
    --contribution-default-borderColor-4: #cdd9e50d;
    --contribution-halloween-bgColor-1: #fac68f;
    --contribution-halloween-bgColor-2: #c46212;
    --contribution-halloween-bgColor-3: #984b10;
    --contribution-halloween-bgColor-4: #e3d04f;
    --contribution-winter-bgColor-1: #143d79;
    --contribution-winter-bgColor-2: #255ab2;
    --contribution-winter-bgColor-3: #539bf5;
    --contribution-winter-bgColor-4: #c6e6ff;
    --control-bgColor-active: #3d444d;
    --control-bgColor-hover: #2f3742;
    --control-bgColor-rest: #2a313c;
    --control-borderColor-disabled: #656c761a;
    --control-borderColor-selected: #f0f6fc;
    --control-danger-fgColor-hover: #f47067;
    --control-transparent-bgColor-active: #656c7633;
    --control-transparent-bgColor-hover: #656c7626;
    --control-transparent-bgColor-rest: #00000000;
    --control-transparent-bgColor-selected: #656c761a;
    --control-transparent-borderColor-active: #00000000;
    --control-transparent-borderColor-hover: #00000000;
    --control-transparent-borderColor-rest: #00000000;
    --controlTrack-bgColor-active: #3d444d;
    --controlTrack-bgColor-hover: #2f3742;
    --controlTrack-bgColor-rest: #2a313c;
    --controlTrack-borderColor-rest: #3d444d;
    --counter-borderColor: #00000000;
    --data-auburn-color-emphasis: #a86f6b;
    --data-auburn-color-muted: #271817;
    --data-blue-color-emphasis: #0576ff;
    --data-blue-color-muted: #001a47;
    --data-brown-color-emphasis: #94774c;
    --data-brown-color-muted: #241c14;
    --data-coral-color-emphasis: #e1430e;
    --data-coral-color-muted: #351008;
    --data-gray-color-emphasis: #576270;
    --data-gray-color-muted: #1c1c1c;
    --data-green-color-emphasis: #2f6f37;
    --data-green-color-muted: #122117;
    --data-lemon-color-emphasis: #977b0c;
    --data-lemon-color-muted: #291d00;
    --data-lime-color-emphasis: #5f892f;
    --data-lime-color-muted: #141f0f;
    --data-olive-color-emphasis: #7a8321;
    --data-olive-color-muted: #171e0b;
    --data-orange-color-emphasis: #984b10;
    --data-orange-color-muted: #311708;
    --data-pine-color-emphasis: #18915e;
    --data-pine-color-muted: #082119;
    --data-pink-color-emphasis: #d34591;
    --data-pink-color-muted: #2d1524;
    --data-plum-color-emphasis: #b643ef;
    --data-plum-color-muted: #2a0e3f;
    --data-purple-color-emphasis: #975bf1;
    --data-purple-color-muted: #211047;
    --data-red-color-emphasis: #eb3342;
    --data-red-color-muted: #3c0614;
    --data-teal-color-emphasis: #106c70;
    --data-teal-color-muted: #041f25;
    --data-yellow-color-emphasis: #895906;
    --data-yellow-color-muted: #2e1a00;
    --diffBlob-additionNum-bgColor: #57ab5a4d;
    --diffBlob-additionWord-bgColor: #46954a66;
    --diffBlob-deletionNum-bgColor: #e5534b4d;
    --diffBlob-deletionWord-bgColor: #e5534b66;
    --diffBlob-hunkNum-bgColor-rest: #143d79;
    --display-auburn-bgColor-emphasis: #87534f;
    --display-auburn-bgColor-muted: #271817;
    --display-auburn-borderColor-emphasis: #a86f6b;
    --display-auburn-borderColor-muted: #3a2422;
    --display-auburn-fgColor: #bf9592;
    --display-blue-bgColor-emphasis: #005bd1;
    --display-blue-bgColor-muted: #001a47;
    --display-blue-borderColor-emphasis: #0576ff;
    --display-blue-borderColor-muted: #002766;
    --display-blue-fgColor: #4da0ff;
    --display-brown-bgColor-emphasis: #755e3e;
    --display-brown-bgColor-muted: #241c14;
    --display-brown-borderColor-emphasis: #94774c;
    --display-brown-borderColor-muted: #342a1d;
    --display-brown-fgColor: #b69a6d;
    --display-coral-bgColor-emphasis: #c31328;
    --display-coral-bgColor-muted: #3c0614;
    --display-coral-borderColor-emphasis: #eb3342;
    --display-coral-borderColor-muted: #58091a;
    --display-coral-fgColor: #f27d83;
    --display-cyan-bgColor-emphasis: #036a8c;
    --display-cyan-bgColor-muted: #001f29;
    --display-cyan-borderColor-emphasis: #0587b3;
    --display-cyan-borderColor-muted: #002e3d;
    --display-cyan-fgColor: #07ace4;
    --display-gray-bgColor-emphasis: #576270;
    --display-gray-bgColor-muted: #1c1c1c;
    --display-gray-borderColor-emphasis: #6e7f96;
    --display-gray-borderColor-muted: #2a2b2d;
    --display-gray-fgColor: #92a1b5;
    --display-green-bgColor-emphasis: #2f6f37;
    --display-green-bgColor-muted: #122117;
    --display-green-borderColor-emphasis: #388f3f;
    --display-green-borderColor-muted: #182f1f;
    --display-green-fgColor: #41b445;
    --display-indigo-bgColor-emphasis: #514ed4;
    --display-indigo-bgColor-muted: #1b183f;
    --display-indigo-borderColor-emphasis: #7070e1;
    --display-indigo-borderColor-muted: #25215f;
    --display-indigo-fgColor: #9899ec;
    --display-lemon-bgColor-emphasis: #786008;
    --display-lemon-bgColor-muted: #291d00;
    --display-lemon-borderColor-emphasis: #977b0c;
    --display-lemon-borderColor-muted: #372901;
    --display-lemon-fgColor: #ba9b12;
    --display-lime-bgColor-emphasis: #496c28;
    --display-lime-bgColor-muted: #141f0f;
    --display-lime-borderColor-emphasis: #5f892f;
    --display-lime-borderColor-muted: #1f3116;
    --display-lime-fgColor: #7dae37;
    --display-olive-bgColor-emphasis: #5e681d;
    --display-olive-bgColor-muted: #171e0b;
    --display-olive-borderColor-emphasis: #7a8321;
    --display-olive-borderColor-muted: #252d10;
    --display-olive-fgColor: #a2a626;
    --display-orange-bgColor-emphasis: #984b10;
    --display-orange-bgColor-muted: #311708;
    --display-orange-borderColor-emphasis: #c46212;
    --display-orange-borderColor-muted: #43200a;
    --display-orange-fgColor: #ed8326;
    --display-pine-bgColor-emphasis: #14714c;
    --display-pine-bgColor-muted: #082119;
    --display-pine-borderColor-emphasis: #18915e;
    --display-pine-borderColor-muted: #0b3224;
    --display-pine-fgColor: #1bb673;
    --display-pink-bgColor-emphasis: #ac2f74;
    --display-pink-bgColor-muted: #2d1524;
    --display-pink-borderColor-emphasis: #d34591;
    --display-pink-borderColor-muted: #451c35;
    --display-pink-fgColor: #e57bb2;
    --display-plum-bgColor-emphasis: #9518d8;
    --display-plum-bgColor-muted: #2a0e3f;
    --display-plum-borderColor-emphasis: #b643ef;
    --display-plum-borderColor-muted: #40125e;
    --display-plum-fgColor: #d07ef7;
    --display-purple-bgColor-emphasis: #7730e8;
    --display-purple-bgColor-muted: #211047;
    --display-purple-borderColor-emphasis: #975bf1;
    --display-purple-borderColor-muted: #31146b;
    --display-purple-fgColor: #b687f7;
    --display-red-bgColor-emphasis: #c31328;
    --display-red-bgColor-muted: #3c0614;
    --display-red-borderColor-emphasis: #eb3342;
    --display-red-borderColor-muted: #58091a;
    --display-red-fgColor: #f27d83;
    --display-teal-bgColor-emphasis: #106c70;
    --display-teal-bgColor-muted: #041f25;
    --display-teal-borderColor-emphasis: #158a8a;
    --display-teal-borderColor-muted: #073036;
    --display-teal-fgColor: #1cb0ab;
    --display-yellow-bgColor-emphasis: #895906;
    --display-yellow-bgColor-muted: #2e1a00;
    --display-yellow-borderColor-emphasis: #aa7109;
    --display-yellow-borderColor-muted: #3d2401;
    --display-yellow-fgColor: #d3910d;
    --fgColor-attention: #c69026;
    --fgColor-danger: #e5534b;
    --fgColor-default: #d1d7e0;
    --fgColor-disabled: #656c76;
    --fgColor-done: #986ee2;
    --fgColor-link: var(--fgColor-accent);
    --fgColor-muted: #9198a1;
    --fgColor-neutral: #9198a1;
    --fgColor-onEmphasis: #f0f6fc;
    --fgColor-severe: #cc6b2c;
    --fgColor-sponsors: #c96198;
    --fgColor-success: #57ab5a;
    --header-bgColor: #151b23f2;
    --header-borderColor-divider: #656c76;
    --header-fgColor-logo: #f0f6fc;
    --headerSearch-bgColor: #0d1117;
    --headerSearch-borderColor: #2a313c;
    --highlight-neutral-bgColor: #c6902666;
    --label-auburn-bgColor-active: #543331;
    --label-auburn-bgColor-hover: #3a2422;
    --label-auburn-bgColor-rest: #271817;
    --label-auburn-fgColor-active: #d4b7b5;
    --label-auburn-fgColor-hover: #c6a19f;
    --label-auburn-fgColor-rest: #bf9592;
    --label-blue-bgColor-active: #00378a;
    --label-blue-bgColor-hover: #002766;
    --label-blue-bgColor-rest: #001a47;
    --label-blue-fgColor-active: #85c2ff;
    --label-blue-fgColor-hover: #61adff;
    --label-blue-fgColor-rest: #4da0ff;
    --label-brown-bgColor-active: #483a28;
    --label-brown-bgColor-hover: #342a1d;
    --label-brown-bgColor-rest: #241c14;
    --label-brown-fgColor-active: #cdbb98;
    --label-brown-fgColor-hover: #bfa77d;
    --label-brown-fgColor-rest: #b69a6d;
    --label-coral-bgColor-active: #72220d;
    --label-coral-bgColor-hover: #51180b;
    --label-coral-bgColor-rest: #351008;
    --label-coral-fgColor-active: #fdaa86;
    --label-coral-fgColor-hover: #fa8c61;
    --label-coral-fgColor-rest: #f7794b;
    --label-cyan-bgColor-active: #014156;
    --label-cyan-bgColor-hover: #002e3d;
    --label-cyan-bgColor-rest: #001f29;
    --label-cyan-fgColor-active: #45cbf7;
    --label-cyan-fgColor-hover: #09b7f1;
    --label-cyan-fgColor-rest: #07ace4;
    --label-gray-bgColor-active: #393d41;
    --label-gray-bgColor-hover: #2a2b2d;
    --label-gray-bgColor-rest: #1c1c1c;
    --label-gray-fgColor-active: #b3c0d1;
    --label-gray-fgColor-hover: #9babbf;
    --label-gray-fgColor-rest: #92a1b5;
    --label-green-bgColor-active: #214529;
    --label-green-bgColor-hover: #182f1f;
    --label-green-bgColor-rest: #122117;
    --label-green-fgColor-active: #75d36f;
    --label-green-fgColor-hover: #46c144;
    --label-green-fgColor-rest: #41b445;
    --label-indigo-bgColor-active: #312c90;
    --label-indigo-bgColor-hover: #25215f;
    --label-indigo-bgColor-rest: #1b183f;
    --label-indigo-fgColor-active: #b7baf6;
    --label-indigo-fgColor-hover: #a2a5f1;
    --label-indigo-fgColor-rest: #9899ec;
    --label-lemon-bgColor-active: #4f3c02;
    --label-lemon-bgColor-hover: #372901;
    --label-lemon-bgColor-rest: #291d00;
    --label-lemon-fgColor-active: #d7bc1d;
    --label-lemon-fgColor-hover: #c4a717;
    --label-lemon-fgColor-rest: #ba9b12;
    --label-lime-bgColor-active: #2c441d;
    --label-lime-bgColor-hover: #1f3116;
    --label-lime-bgColor-rest: #141f0f;
    --label-lime-fgColor-active: #9fcc3e;
    --label-lime-fgColor-hover: #89ba36;
    --label-lime-fgColor-rest: #7dae37;
    --label-olive-bgColor-active: #374115;
    --label-olive-bgColor-hover: #252d10;
    --label-olive-bgColor-rest: #171e0b;
    --label-olive-fgColor-active: #cbc025;
    --label-olive-fgColor-hover: #b2af24;
    --label-olive-fgColor-rest: #a2a626;
    --label-orange-bgColor-active: #632f0d;
    --label-orange-bgColor-hover: #43200a;
    --label-orange-bgColor-rest: #311708;
    --label-orange-fgColor-active: #f6b06a;
    --label-orange-fgColor-hover: #f1933b;
    --label-orange-fgColor-rest: #ed8326;
    --label-pine-bgColor-active: #0e4430;
    --label-pine-bgColor-hover: #0b3224;
    --label-pine-bgColor-rest: #082119;
    --label-pine-fgColor-active: #1bda81;
    --label-pine-fgColor-hover: #1ac176;
    --label-pine-fgColor-rest: #1bb673;
    --label-pink-bgColor-active: #65244a;
    --label-pink-bgColor-hover: #451c35;
    --label-pink-bgColor-rest: #2d1524;
    --label-pink-fgColor-active: #f4a9cd;
    --label-pink-fgColor-hover: #ec8dbd;
    --label-pink-fgColor-rest: #e57bb2;
    --label-plum-bgColor-active: #5c1688;
    --label-plum-bgColor-hover: #40125e;
    --label-plum-bgColor-rest: #2a0e3f;
    --label-plum-fgColor-active: #e4a5fd;
    --label-plum-fgColor-hover: #d889fa;
    --label-plum-fgColor-rest: #d07ef7;
    --label-purple-bgColor-active: #481a9e;
    --label-purple-bgColor-hover: #31146b;
    --label-purple-bgColor-rest: #211047;
    --label-purple-fgColor-active: #d2affd;
    --label-purple-fgColor-hover: #c398fb;
    --label-purple-fgColor-rest: #b687f7;
    --label-red-bgColor-active: #790c20;
    --label-red-bgColor-hover: #58091a;
    --label-red-bgColor-rest: #3c0614;
    --label-red-fgColor-active: #f7adab;
    --label-red-fgColor-hover: #f48b8d;
    --label-red-fgColor-rest: #f27d83;
    --label-teal-bgColor-active: #0a464d;
    --label-teal-bgColor-hover: #073036;
    --label-teal-bgColor-rest: #041f25;
    --label-teal-fgColor-active: #24d6c4;
    --label-teal-fgColor-hover: #1fbdb2;
    --label-teal-fgColor-rest: #1cb0ab;
    --label-yellow-bgColor-active: #5a3702;
    --label-yellow-bgColor-hover: #3d2401;
    --label-yellow-bgColor-rest: #2e1a00;
    --label-yellow-fgColor-active: #edb431;
    --label-yellow-fgColor-hover: #df9e11;
    --label-yellow-fgColor-rest: #d3910d;
    --menu-bgColor-active: #151b23;
    --overlay-backdrop-bgColor: #262c3666;
    --overlay-bgColor: #2a313c;
    --overlay-borderColor: #3d444db3;
    --reactionButton-selected-bgColor-rest: #4184e433;
    --reactionButton-selected-fgColor-hover: #6cb6ff;
    --selectMenu-bgColor-active: #143d79;
    --sideNav-bgColor-selected: #212830;
    --skeletonLoader-bgColor: #656c7633;
    --timelineBadge-bgColor: #262c36;
    --topicTag-borderColor: #00000000;
    --underlineNav-borderColor-active: #ec775c;
    --avatar-bgColor: #cdd9e51a;
    --bgColor-black: #1c2128;
    --bgColor-closed-emphasis: var(--bgColor-danger-emphasis);
    --bgColor-closed-muted: var(--bgColor-danger-muted);
    --bgColor-inverse: #cdd9e5;
    --bgColor-open-emphasis: var(--bgColor-success-emphasis);
    --bgColor-open-muted: var(--bgColor-success-muted);
    --bgColor-upsell-emphasis: var(--bgColor-done-emphasis);
    --bgColor-upsell-muted: var(--bgColor-done-muted);
    --bgColor-white: #cdd9e5;
    --border-accent-emphasis: 0.0625rem solid #316dca;
    --border-accent-muted: 0.0625rem solid #4184e466;
    --border-attention-emphasis: 0.0625rem solid #966600;
    --border-attention-muted: 0.0625rem solid #ae7c1466;
    --border-danger-emphasis: 0.0625rem solid #c93c37;
    --border-danger-muted: 0.0625rem solid #e5534b66;
    --border-default: 0.0625rem solid #3d444d;
    --border-disabled: 0.0625rem solid #656c761a;
    --border-done-emphasis: 0.0625rem solid #8256d0;
    --border-done-muted: 0.0625rem solid #986ee266;
    --border-emphasis: 0.0625rem solid #656c76;
    --border-muted: 0.0625rem solid #3d444db3;
    --border-neutral-muted: 0.0625rem solid #3d444db3;
    --border-severe-emphasis: 0.0625rem solid #ae5622;
    --border-severe-muted: 0.0625rem solid #cc6b2c66;
    --border-sponsors-emphasis: 0.0625rem solid #ae4c82;
    --border-sponsors-muted: 0.0625rem solid #c9619866;
    --border-success-emphasis: 0.0625rem solid #347d39;
    --border-success-muted: 0.0625rem solid #46954a66;
    --border-transparent: 0.0625rem solid #00000000;
    --borderColor-closed-emphasis: var(--borderColor-danger-emphasis);
    --borderColor-closed-muted: var(--borderColor-danger-muted);
    --borderColor-neutral-emphasis: var(--borderColor-emphasis);
    --borderColor-open-emphasis: var(--borderColor-success-emphasis);
    --borderColor-open-muted: var(--borderColor-success-muted);
    --borderColor-translucent: #cdd9e526;
    --borderColor-upsell-emphasis: var(--borderColor-done-emphasis);
    --borderColor-upsell-muted: var(--borderColor-done-muted);
    --button-danger-bgColor-rest: var(--control-bgColor-rest);
    --button-danger-fgColor-active: #cdd9e5;
    --button-danger-fgColor-disabled: #e5534b80;
    --button-danger-fgColor-hover: #cdd9e5;
    --button-danger-iconColor-hover: #cdd9e5;
    --button-default-bgColor-active: var(--control-bgColor-active);
    --button-default-bgColor-hover: var(--control-bgColor-hover);
    --button-default-bgColor-rest: var(--control-bgColor-rest);
    --button-default-bgColor-selected: var(--control-bgColor-active);
    --button-default-borderColor-active: var(--button-default-borderColor-rest);
    --button-default-borderColor-disabled: var(--control-borderColor-disabled);
    --button-default-borderColor-hover: var(--button-default-borderColor-rest);
    --button-invisible-bgColor-active: var(--control-transparent-bgColor-active);
    --button-invisible-bgColor-hover: var(--control-transparent-bgColor-hover);
    --button-invisible-bgColor-rest: var(--control-transparent-bgColor-rest);
    --button-invisible-borderColor-hover: var(--control-transparent-borderColor-hover);
    --button-invisible-borderColor-rest: var(--control-transparent-borderColor-rest);
    --button-outline-bgColor-hover: var(--control-bgColor-hover);
    --button-outline-fgColor-active: #cdd9e5;
    --button-primary-bgColor-rest: var(--bgColor-success-emphasis);
    --button-primary-borderColor-rest: #cdd9e51a;
    --button-primary-fgColor-disabled: #cdd9e566;
    --buttonCounter-danger-bgColor-disabled: #c93c370d;
    --buttonCounter-danger-bgColor-hover: #cdd9e533;
    --buttonCounter-danger-fgColor-disabled: #e5534b80;
    --buttonCounter-danger-fgColor-hover: #cdd9e5;
    --buttonCounter-danger-fgColor-rest: var(--fgColor-danger);
    --buttonCounter-invisible-bgColor-rest: var(--bgColor-neutral-muted);
    --buttonCounter-outline-bgColor-disabled: #316dca0d;
    --card-bgColor: var(--bgColor-muted);
    --codeMirror-activeline-bgColor: var(--bgColor-neutral-muted);
    --codeMirror-bgColor: var(--bgColor-default);
    --codeMirror-cursor-fgColor: var(--fgColor-default);
    --codeMirror-fgColor: var(--fgColor-default);
    --codeMirror-gutterMarker-fgColor-default: var(--bgColor-default);
    --codeMirror-gutterMarker-fgColor-muted: var(--fgColor-muted);
    --codeMirror-gutters-bgColor: var(--bgColor-default);
    --codeMirror-lineNumber-fgColor: var(--fgColor-muted);
    --codeMirror-lines-bgColor: var(--bgColor-default);
    --codeMirror-matchingBracket-fgColor: var(--fgColor-default);
    --codeMirror-selection-bgColor: var(--borderColor-accent-muted);
    --color-ansi-white-bright: #cdd9e5;
    --control-bgColor-disabled: var(--bgColor-disabled);
    --control-bgColor-selected: var(--control-bgColor-rest);
    --control-borderColor-danger: var(--borderColor-danger-emphasis);
    --control-borderColor-emphasis: var(--borderColor-emphasis);
    --control-borderColor-success: var(--borderColor-success-emphasis);
    --control-borderColor-warning: var(--borderColor-attention-emphasis);
    --control-checked-bgColor-disabled: var(--fgColor-disabled);
    --control-checked-bgColor-rest: var(--bgColor-accent-emphasis);
    --control-checked-fgColor-disabled: #1c2128;
    --control-checked-fgColor-rest: var(--fgColor-onEmphasis);
    --control-danger-bgColor-active: #e5534b66;
    --control-danger-bgColor-hover: var(--bgColor-danger-muted);
    --control-danger-fgColor-rest: var(--fgColor-danger);
    --control-fgColor-disabled: var(--fgColor-disabled);
    --control-fgColor-placeholder: var(--fgColor-muted);
    --control-fgColor-rest: var(--fgColor-default);
    --control-iconColor-rest: var(--fgColor-muted);
    --control-transparent-bgColor-disabled: var(--bgColor-disabled);
    --controlKnob-bgColor-checked: #cdd9e5;
    --controlKnob-bgColor-rest: var(--bgColor-inset);
    --controlTrack-bgColor-disabled: var(--fgColor-disabled);
    --controlTrack-borderColor-disabled: var(--fgColor-disabled);
    --controlTrack-fgColor-disabled: var(--fgColor-onEmphasis);
    --controlTrack-fgColor-rest: var(--fgColor-muted);
    --counter-bgColor-emphasis: var(--bgColor-neutral-emphasis);
    --counter-bgColor-muted: var(--bgColor-neutral-muted);
    --data-auburn-color-gradientStop-1: var(--data-auburn-color-muted);
    --data-auburn-color-gradientStop-2: #27181700;
    --data-blue-color-gradientStop-1: var(--data-blue-color-muted);
    --data-blue-color-gradientStop-2: #001a4700;
    --data-brown-color-gradientStop-1: var(--data-brown-color-muted);
    --data-brown-color-gradientStop-2: #241c1400;
    --data-coral-color-gradientStop-1: var(--data-coral-color-muted);
    --data-coral-color-gradientStop-2: #35100800;
    --data-gray-color-gradientStop-1: var(--data-gray-color-muted);
    --data-gray-color-gradientStop-2: #1c1c1c00;
    --data-green-color-gradientStop-1: var(--data-green-color-muted);
    --data-green-color-gradientStop-2: #12211700;
    --data-lemon-color-gradientStop-1: var(--data-lemon-color-muted);
    --data-lemon-color-gradientStop-2: #291d0000;
    --data-lime-color-gradientStop-1: var(--data-lime-color-muted);
    --data-lime-color-gradientStop-2: #141f0f00;
    --data-olive-color-gradientStop-1: var(--data-olive-color-muted);
    --data-olive-color-gradientStop-2: #171e0b00;
    --data-orange-color-gradientStop-1: var(--data-orange-color-muted);
    --data-orange-color-gradientStop-2: #31170800;
    --data-pine-color-gradientStop-1: var(--data-pine-color-muted);
    --data-pine-color-gradientStop-2: #08211900;
    --data-pink-color-gradientStop-1: var(--data-pink-color-muted);
    --data-pink-color-gradientStop-2: #2d152400;
    --data-plum-color-gradientStop-1: var(--data-plum-color-muted);
    --data-plum-color-gradientStop-2: #2a0e3f00;
    --data-purple-color-gradientStop-1: var(--data-purple-color-muted);
    --data-purple-color-gradientStop-2: #21104700;
    --data-red-color-gradientStop-1: var(--data-red-color-muted);
    --data-red-color-gradientStop-2: #3c061400;
    --data-teal-color-gradientStop-1: var(--data-teal-color-muted);
    --data-teal-color-gradientStop-2: #041f2500;
    --data-yellow-color-gradientStop-1: var(--data-yellow-color-muted);
    --data-yellow-color-gradientStop-2: #2e1a0000;
    --diffBlob-additionLine-bgColor: var(--bgColor-success-muted);
    --diffBlob-additionLine-fgColor: var(--fgColor-default);
    --diffBlob-additionNum-fgColor: var(--fgColor-default);
    --diffBlob-additionWord-fgColor: var(--fgColor-default);
    --diffBlob-deletionLine-bgColor: var(--bgColor-danger-muted);
    --diffBlob-deletionLine-fgColor: var(--fgColor-default);
    --diffBlob-deletionNum-fgColor: var(--fgColor-default);
    --diffBlob-deletionWord-fgColor: var(--fgColor-default);
    --diffBlob-emptyLine-bgColor: var(--bgColor-muted);
    --diffBlob-emptyNum-bgColor: var(--bgColor-muted);
    --diffBlob-expander-iconColor: var(--fgColor-muted);
    --diffBlob-hunkLine-bgColor: var(--bgColor-accent-muted);
    --diffBlob-hunkLine-fgColor: var(--fgColor-muted);
    --diffBlob-hunkNum-bgColor-hover: var(--bgColor-accent-emphasis);
    --diffBlob-hunkNum-fgColor-hover: var(--fgColor-onEmphasis);
    --diffBlob-hunkNum-fgColor-rest: var(--fgColor-default);
    --fgColor-black: #1c2128;
    --fgColor-closed: var(--fgColor-danger);
    --fgColor-onInverse: #1c2128;
    --fgColor-open: var(--fgColor-success);
    --fgColor-upsell: var(--fgColor-done);
    --fgColor-white: #cdd9e5;
    --focus-outlineColor: var(--borderColor-accent-emphasis);
    --header-fgColor-default: #cdd9e5b3;
    --page-header-bgColor: var(--bgColor-default);
    --reactionButton-selected-fgColor-rest: var(--fgColor-link);
    --selectMenu-borderColor: var(--borderColor-default);
    --selection-bgColor: #316dcab3;
    --shadow-floating-large: 0px 0px 0px 1px #3d444d, 0px 24px 48px 0px #1c2128;
    --shadow-floating-legacy: 0px 6px 12px -3px #1c212866, 0px 6px 18px 0px #1c212866;
    --shadow-floating-medium: 0px 0px 0px 1px #3d444d, 0px 8px 16px -4px #1c212866, 0px 4px 32px -4px #1c212866, 0px 24px 48px -12px #1c212866, 0px 48px 96px -24px #1c212866;
    --shadow-floating-small: 0px 0px 0px 1px #3d444d, 0px 6px 12px -3px #1c212866, 0px 6px 18px 0px #1c212866;
    --shadow-floating-xlarge: 0px 0px 0px 1px #3d444d, 0px 32px 64px 0px #1c2128;
    --shadow-inset: inset 0px 1px 0px 0px #1c21283d;
    --shadow-resting-medium: 0px 1px 1px 0px #1c212866, 0px 3px 6px 0px #1c2128cc;
    --shadow-resting-small: 0px 1px 1px 0px #1c212899, 0px 1px 3px 0px #1c212899;
    --shadow-resting-xsmall: 0px 1px 1px 0px #1c2128cc;
    --tooltip-bgColor: var(--bgColor-emphasis);
    --tooltip-fgColor: var(--fgColor-onEmphasis);
    --treeViewItem-leadingVisual-iconColor-rest: var(--fgColor-muted);
    --underlineNav-borderColor-hover: var(--borderColor-neutral-muted);
    --underlineNav-iconColor-rest: var(--fgColor-muted);
    --avatar-borderColor: var(--borderColor-translucent);
    --border-closed-emphasis: var(--border-danger-emphasis);
    --border-closed-muted: var(--border-danger-muted);
    --border-neutral-emphasis: 0.0625rem solid #656c76;
    --border-open-emphasis: var(--border-success-emphasis);
    --border-open-muted: var(--border-success-muted);
    --border-upsell-emphasis: 0.0625rem solid #8256d0;
    --border-upsell-muted: 0.0625rem solid #986ee266;
    --button-danger-bgColor-disabled: var(--control-bgColor-disabled);
    --button-danger-borderColor-hover: var(--button-primary-borderColor-rest);
    --button-default-bgColor-disabled: var(--control-bgColor-disabled);
    --button-default-fgColor-rest: var(--control-fgColor-rest);
    --button-invisible-fgColor-active: var(--control-fgColor-rest);
    --button-invisible-fgColor-disabled: var(--control-fgColor-disabled);
    --button-invisible-fgColor-hover: var(--control-fgColor-rest);
    --button-invisible-fgColor-rest: var(--control-fgColor-rest);
    --button-invisible-iconColor-disabled: var(--control-fgColor-disabled);
    --button-outline-bgColor-disabled: var(--control-bgColor-disabled);
    --button-outline-borderColor-hover: var(--button-default-borderColor-hover);
    --button-primary-borderColor-active: var(--button-primary-borderColor-rest);
    --button-primary-borderColor-hover: var(--button-primary-borderColor-rest);
    --control-checked-borderColor-disabled: var(--control-checked-bgColor-disabled);
    --control-checked-borderColor-rest: var(--control-checked-bgColor-rest);
    --controlKnob-bgColor-disabled: var(--control-bgColor-disabled);
    --controlKnob-borderColor-checked: var(--control-checked-bgColor-rest);
    --controlKnob-borderColor-disabled: var(--control-bgColor-disabled);
    --controlKnob-borderColor-rest: var(--control-borderColor-emphasis);
    --data-auburn-gradient: linear-gradient(180deg, #271817 0%, #27181700 100%);
    --data-blue-gradient: linear-gradient(180deg, #001a47 0%, #001a4700 100%);
    --data-brown-gradient: linear-gradient(180deg, #241c14 0%, #241c1400 100%);
    --data-coral-gradient: linear-gradient(180deg, #351008 0%, #35100800 100%);
    --data-gray-gradient: linear-gradient(180deg, #1c1c1c 0%, #1c1c1c00 100%);
    --data-green-gradient: linear-gradient(180deg, #122117 0%, #12211700 100%);
    --data-lemon-gradient: linear-gradient(180deg, #291d00 0%, #291d0000 100%);
    --data-lime-gradient: linear-gradient(180deg, #141f0f 0%, #141f0f00 100%);
    --data-olive-gradient: linear-gradient(180deg, #171e0b 0%, #171e0b00 100%);
    --data-orange-gradient: linear-gradient(180deg, #311708 0%, #31170800 100%);
    --data-pine-gradient: linear-gradient(180deg, #082119 0%, #08211900 100%);
    --data-pink-gradient: linear-gradient(180deg, #2d1524 0%, #2d152400 100%);
    --data-plum-gradient: linear-gradient(180deg, #2a0e3f 0%, #2a0e3f00 100%);
    --data-purple-gradient: linear-gradient(180deg, #211047 0%, #21104700 100%);
    --data-red-gradient: linear-gradient(180deg, #3c0614 0%, #3c061400 100%);
    --data-teal-gradient: linear-gradient(180deg, #041f25 0%, #041f2500 100%);
    --data-yellow-gradient: linear-gradient(180deg, #2e1a00 0%, #2e1a0000 100%);
    --focus-outline: 2px solid #316dca;
    --button-danger-borderColor-active: var(--button-danger-borderColor-hover);
    --button-outline-borderColor-active: var(--button-outline-borderColor-hover);
  }
}
